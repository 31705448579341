import { Button, Card, Input, Space, Table, TableColumnsType, Tooltip } from 'antd';
import { UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons';

import CustomModal from '../../crm/common/CustomModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { callBackEnd } from '../../../utils/backEndUtils';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useState } from 'react';

export default function NSAUsersList(props: Readonly<INSAPendingUsersListProps>) {
    const { users, title, refreshUsers } = props;
    const [search, setSearch] = useState<string>('');

    const [modalUser, setModalUser] = useState<UserProfile | null>(null);
    const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
    const [showAcceptModal, setShowAcceptModal] = useState<boolean>(false);

    const showModal = (user: UserProfile, action: string) => {
        setModalUser(user);
        if (action === 'accept') setShowAcceptModal(true);
        else if (action === 'reject') setShowRejectModal(true);
    }

    const handleRejectModalResponse = (response: boolean) => {
        if (response) {
            async function rejectUser() {
                try {
                    await callBackEnd('portal', 'put', `namespaces/${modalUser?.namespaceId}/join/${modalUser?.uid}/reject`).catch((e: any) => { console.log(e); });
                    setShowRejectModal(false);
                    setModalUser(null);
                    toast.success(`User ${modalUser?.email} has been rejected from the namespace`);
                    refreshUsers();
                }
                catch (e: any) {
                    toast.error(`Error rejecting user ${modalUser?.email} from the namespace`);
                    setShowRejectModal(false);
                    setModalUser(null);
                }
            }
            rejectUser();
        }
        else {
            setShowRejectModal(false);
            setModalUser(null);
        }
    }
    const handleAcceptModalResponse = (response: boolean) => {
        if (response) {
            async function acceptUser() {
                try {
                    const response: any = await callBackEnd('portal', 'put', `namespaces/${modalUser?.namespaceId}/join/${modalUser?.uid}/accept`).catch((e: any) => { console.log(e); });
                    setShowAcceptModal(false);
                    console.log("🚀 ~ acceptUser ~ response:", response);
                    setModalUser(null);
                    toast.success(`User ${modalUser?.email} has been accepted from the namespace`);
                    refreshUsers();
                }
                catch (e: any) {
                    toast.error(`Error accepting user ${modalUser?.email} from the namespace`);
                    setShowAcceptModal(false);
                    setModalUser(null);
                }
            }
            acceptUser();
        }
        else {
            setShowAcceptModal(false);
            setModalUser(null);
        }
    }

    const columns: TableColumnsType<UserProfile> = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName'
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
        },

        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'x',
            render: (value: any, u: any) =>
                <Space size="small">
                    <Tooltip title="Reject user from Namespace" placement="top">
                        <Button type="primary" onClick={() => showModal(u, 'reject')} danger>
                            <UserDeleteOutlined /> Reject
                        </Button>
                    </Tooltip>
                    <Tooltip title="Accept user into Namespace" placement="top">
                        <Button type="primary" onClick={() => showModal(u, 'accept')}>
                            <UserAddOutlined /> Accept
                        </Button>
                    </Tooltip>
                </Space>
        }
    ];

    return (
        <>

            <Card
                title={<><FontAwesomeIcon icon={faUser}></FontAwesomeIcon>{' '}{title}</>}
                type='inner'
                className="userList"
                extra={
                    <Input type="text" placeholder="Search" className="search" onChange={(e: any) => setSearch(e.target.value)} />
                }
            >
                <Table
                    dataSource={users.filter((u: UserProfile) => {
                        if (search === '') return u;
                        else if (u.firstName && u?.firstName.toLowerCase().includes(search.toLowerCase())) return u;
                        else if (u.lastName && u?.lastName.toLowerCase().includes(search.toLowerCase())) return u;
                        else if (u.email && u?.email.toLowerCase().includes(search.toLowerCase())) return u;
                        else if (u.company && u?.company.toLowerCase().includes(search.toLowerCase())) return u;
                        // else if (u.status && u.status.toLowerCase().includes(search.toLowerCase())) return u;
                        else return null;
                    })}
                    columns={columns}
                    virtual
                    rowKey={(record: UserProfile) => record.uid}
                    scroll={{ x: 'max-content' }}
                    pagination={false}
                />
            </Card>

            {showAcceptModal && modalUser !== null &&
                <CustomModal title="Accept user into namespace" message={`Are you sure you want to accept the user ${modalUser.email} from this Namespace ?`} callback={handleAcceptModalResponse} />
            }
            {showRejectModal && modalUser !== null &&
                <CustomModal title="Reject user from namespace" message={`Are you sure you want to reject the user ${modalUser.email} from this Namespace ?`} callback={handleRejectModalResponse} />
            }

        </>
    )

}
