import { useEffect, useState } from 'react'

import Loading from '../Layout/Loading';
import ShowError from '../Layout/ShowError';
import { Tooltip } from 'antd';
import { callBackEnd } from '../../utils/backEndUtils';

export default function CollectionStatus(props: Readonly<{ targetRealm: string, collection: string }>) {
    const { targetRealm, collection } = props;

    const [collectionStatus, setCollectionStatus] = useState<string>('red');
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        async function getCollectionStatus() {
            try {
                const response: any = await callBackEnd(targetRealm, 'get', `api/v1/core/cluster-status/${collection}`);
                setCollectionStatus(response.status);
            } catch (error: any) {
                console.log(error);
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        getCollectionStatus().catch((error) => { console.log(error) });
    }, [targetRealm, collection])

    const renderStatusIcon = (status: string): string => {
        if (status === 'GREEN' || status === 'UP') {
            return 'success';
        } else if (status === 'YELLOW') {
            return 'warning';
        } else if (status === 'RED' || status === 'OUT_OF_SERVICE' || status === 'DOWN') {
            return 'fail';
        }
        return ''
    }
    return (
        <>
            {!loading && error === null ?
                <>
                    <p>
                        <Tooltip title={collectionStatus}>
                            <span className={`statusBubble ${renderStatusIcon(collectionStatus)}`}></span>{ ' '}{collection.toUpperCase()}
                        </Tooltip>

                    </p>
                </>
                :
                <>
                    {loading && <Loading />}
                    {error && <ShowError error={error} />}
                </>
            }
        </>
    )
}
