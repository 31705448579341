import { toast } from "react-toastify";

export const processJSONMvRecords = async (file: any, selectedProduct: string): Promise<any> => {

    let toReturn: { success: boolean, data: { options: any, context: any, records: any }, errorMessages: string[] } = {
        success: true,
        data: {
            options: null,
            context: null,
            records: []
        },
        errorMessages: []
    }

    //Check is a JSON string is valid
    const isValidJSON = (txt: string) => {
        try {
            JSON.parse(txt);
            return true;
        } catch {
            return false;
        }
    }

    const sanitizeRecords = (records: any[], recordsOrigin: string) => {
        let missingModelValidationCount: number = 0;
        let missingNamesCount: number = 0;
        let missingProfileIdCount: number = 0;
        let missingCategoryCount: number = 0;
        let missingReferenceCount: number = 0;
        let wrongProductRecordCount: number = 0;

        let jsonSanitized: any[] = [];
        jsonSanitized = records.filter((record: any) => {
            //Checking names
            // if (!record.hasOwnProperty('object') || typeof record['object'] !== 'string' || record['object'].length === 0) {
            if (!record.hasOwnProperty('object')) {
                missingNamesCount++;
                return false;
            }

            //Check JETSCAN
            if (typeof (record.object) === "string" && selectedProduct !== "JETSCAN") {
                wrongProductRecordCount++;
                return false;
            }
            //Check JETSCAN
            if (typeof (record.object) === "object" && selectedProduct !== "JETFLOW") {
                wrongProductRecordCount++;
                return false;
            }

            //checking modelvalidation presence
            if (!record.hasOwnProperty('modelValidation')) {
                missingModelValidationCount++;
                return false;
            }
            else {
                if (!record.modelValidation.hasOwnProperty('category') || typeof record.modelValidation['category'] !== 'string' || record.modelValidation['category'].length === 0) {
                    missingCategoryCount++;
                    return false;
                }
                if (!record.modelValidation.hasOwnProperty('profileId') || typeof record.modelValidation['profileId'] !== 'string' || record.modelValidation['profileId'].length === 0) {
                    missingProfileIdCount++;
                    return false;
                }
                if (!record.modelValidation.hasOwnProperty('reference') || typeof record.modelValidation['reference'] !== 'string' || record.modelValidation['reference'].length === 0) {
                    missingReferenceCount++;
                    return false;
                }
            }
            return true;
        })
        let errorMessages: string[] = [];
        if (missingModelValidationCount > 0) {
            errorMessages.push(`${missingModelValidationCount} ${(missingModelValidationCount > 1) ? "records are" : "record is"}  ignored because of missing "modelValidation" property`);
        }
        if (missingNamesCount > 0) {
            errorMessages.push(`${missingNamesCount} ${(missingNamesCount > 1) ? "records are" : "record is"}  ignored because of missing ${(recordsOrigin === 'csv') ? "Name" : "object"} property`);
        }
        if (missingProfileIdCount > 0) {
            errorMessages.push(`${missingProfileIdCount} ${(missingProfileIdCount > 1) ? "records are" : "record is"}  ignored because of  missing ${(recordsOrigin === 'csv') ? "ListId" : "profileId"} property in the modelValidation object`);
        }
        if (missingCategoryCount > 0) {
            errorMessages.push(`${missingCategoryCount} ${(missingCategoryCount > 1) ? "records are" : "record is"}  ignored because of  missing "Category" property in the modelValidation object`);
        }
        if (missingReferenceCount > 0) {
            errorMessages.push(`${missingReferenceCount} ${(missingReferenceCount > 1) ? "records are" : "record is"}  ignored because of  missing "Reference" property in the modelValidation object`);
        }
        if (wrongProductRecordCount > 0) {
            errorMessages.push(`${wrongProductRecordCount} ${(wrongProductRecordCount > 1) ? "records are" : "record is"}  ignored because of  wrong engine`);
        }

        return { jsonSanitized, errorMessages };
    }

    const readFileContent = (file: any) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = function (evt: any) {
                const content: string = evt?.target?.result;

                if (isValidJSON(content)) {
                    resolve(JSON.parse(content));
                }
                else {
                    toast.error("Invalid JSON file");
                    reject(new Error("Error while loading your JSON file"));
                }
            }
            reader.onerror = function (evt) {
                toast.error('Unable to read uploaded file');
                reject(new Error('Unable to read uploaded file'));
            }
        })
    }

    await readFileContent(file).then((values: any) => {

        if (values.hasOwnProperty('options')) {
            toReturn.data.options = values.options;
        }
        if (values.hasOwnProperty('context')) {
            toReturn.data.context = values.context;
        }
        if (values.hasOwnProperty('records')) {
            const sanitized: any = sanitizeRecords(values.records, 'json');
            if (sanitized.jsonSanitized !== null) {
                toReturn.data.records = sanitized.jsonSanitized;
                toReturn.errorMessages = sanitized.errorMessages;
            } else {
                toReturn.success = false;
                toReturn.errorMessages = sanitized.errorMessages;
            }
        }

    }).catch((error: any) => {
        toReturn.success = false;
    }
    ).finally(() => {
        return toReturn;
    }
    )

    return new Promise((resolve, reject) => {
        resolve(toReturn);
    }
    )

}