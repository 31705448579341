import { useEffect, useState } from "react";

import { callBackEnd } from "../utils/backEndUtils";
import { toast } from "react-toastify";

export default function useCollections() {
    const [collections, setCollections] = useState<Collection[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        async function getCollections() {
            try {
                setLoading(true);
                const response:any = await callBackEnd('portal', 'get', 'static/collections').catch((error) => {
                    toast.error(`Error while fetching collections`);
                    throw new Error(`Error while fetching collections: ${error}`);
                })
                
                setCollections(response);
                setError(null);
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        getCollections();

    }, []);

    return { collections, loading, error };
}