import { Card, Table, TableColumnsType, Tag, Tooltip } from 'antd';
import { EyeTwoTone, InfoCircleTwoTone } from '@ant-design/icons';
import { SecureWithLogin, useOidcAccessToken } from '../../../../utils/secure';
import { useEffect, useState } from 'react';

import { Content } from 'antd/es/layout/layout';
import LoadingSkeleton from '../../../Layout/LoadingSkeleton';
import Moment from 'react-moment';
import MotionBox from '../../../Layout/Animated/MotionBox';
import ShowError from '../../../Layout/ShowError';
import StatusPills from '../../../crm/common/StatusPills';
import VolumeProgress from '../../../Layout/VolumeProgress';
import { getPackageTypes } from '../../../../utils/getPackageTypes';
import useAllEnvironments from '../../../../hooks/useAllEnvironments';
import useAllProducts from '../../../../hooks/useAllProducts';
import useNamespaceById from '../../../../hooks/useNamespaceById';
import { useOutletContext } from 'react-router-dom';

export default function NSAPackages() {
    const outletContext: { setHeaderTitle: React.Dispatch<React.SetStateAction<string>> } = useOutletContext();
    const { products } = useAllProducts();
    const { getNamespacePackages, getNamespaceServiceAccounts } = useNamespaceById();
    const { allEnv } = useAllEnvironments();
    useEffect(() => {
        outletContext.setHeaderTitle("My Packages");
    }, [outletContext]);

    const { accessTokenPayload } = useOidcAccessToken();
    const [namespaceId, setNamespaceId] = useState<string>("");
    const [packages, setPackages] = useState<any>(null);
    const [loadingPackages, setLoadingPackages] = useState<boolean>(false);
    const [errorPackages, setErrorPackages] = useState<any>(null);
    const [loadingServiceAccounts, setLoadingServiceAccounts] = useState<boolean>(true);
    const [errorServiceAccounts, setErrorServiceAccounts] = useState<any>(null);
    const [serviceAccounts, setServiceAccounts] = useState<ServiceAccount[] | null>(null);



    useEffect(() => {
        if (accessTokenPayload?.namespaceId) {
            setNamespaceId(accessTokenPayload?.namespaceId);
        }
    }, [accessTokenPayload]);

    useEffect(() => {
        async function getPackages() {
            try {
                setLoadingPackages(true);
                const response: any = await getNamespacePackages({ withVolumes: true }).catch((e: any) => console.log(e));
                setPackages(response);
            } catch (error: any) {
                console.log(error);
                setErrorPackages(error);
            } finally {
                setLoadingPackages(false);
            }
        }
        async function getServiceAccounts() {
            setLoadingServiceAccounts(true);
            const response: any = await getNamespaceServiceAccounts().catch((e: any) => {
                console.log(e);
                setErrorServiceAccounts(e)
            }).finally(() => setLoadingServiceAccounts(false));

            setServiceAccounts(response);
        }
        if (namespaceId !== "") {
            getPackages().catch((e: any) => console.log(e));
            getServiceAccounts().catch((e: any) => console.log(e));
        }
    }, [namespaceId, getNamespacePackages, getNamespaceServiceAccounts])

    const countPackageIdInServiceAccount = (packageId: string) => {
        let count: number = 0;
        if (packageId) {
            serviceAccounts?.forEach((sa: ServiceAccount) => {
                if (sa.packageId === packageId) {
                    count++;
                }
            })

        }
        return count;
    }



    const renderOptions = (options: any) => {
        if (options.length > 0) {
            return (
                <Tooltip
                    title={
                        <ul>
                            {options.map((o: any, index: number) => {
                                return (
                                    <li key={index}>
                                        {o.name}
                                        {/* <td>{(o.enabled) ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faBan} />}</td> */}

                                        {o.limits !== null &&
                                            <>
                                                {' > '}
                                                {
                                                    Object.keys(o.limits).map((k: any, index: number) => {
                                                        return (<small key={k.name}>
                                                            {k}
                                                            :{' '}
                                                            {o?.limits[k]}
                                                        </small>)
                                                    })
                                                }
                                            </>
                                        }
                                    </li>
                                )
                            })}
                        </ul>
                    } placement="top">
                    <EyeTwoTone twoToneColor="#52c41a" />
                </Tooltip>
            )
        }
        return "";
    }
    const columns: TableColumnsType<Package> = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => <StatusPills type={status} />,
            filters: [
                { text: 'Enabled', value: "enabled" },
                { text: 'Disabled', value: "disabled" }
            ],
            onFilter: (value: any, p: any) => p.status.toLowerCase() === value,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name: string, record: Package) => {
                return (
                    <>
                        {(record?.description) ?
                            <Tooltip title={record.description}>
                                {name} <InfoCircleTwoTone />
                            </Tooltip>
                            :
                            <>{name}</>
                        }
                    </>
                )
            }
        },
        {
            title: 'Environment',
            dataIndex: 'environment',
            key: 'environment',
            filters: allEnv.map((e: any) => {
                return { text: e.label, value: e.name }
            }),
            onFilter: (value: any, p: any) => p.environment === value,
            render: (env: string) => {
                const color: string = allEnv.find((e: any) => e.name === env)?.color;
                return <Tag color={color}>{env}</Tag>
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type: string) => {
                return <Tag color={"blue"}>{type.replace(/_/g, ' ')}</Tag>
            },
            filters: getPackageTypes().map((t: any) => {
                return { text: t.replace(/_/g, ' '), value: t }
            }
            ),
            onFilter: (value: any, p: any) => p.type === value,
        },
        {
            title: 'Expiration',
            dataIndex: 'expiration',
            key: 'expiration',
            render: (v: Date | null) => {
                if (v === null) {
                    return <>No expiration</>
                }
                return <Moment format="YYYY/MM/DD">{v}</Moment>
            }
        },
        {
            title: 'Options',
            dataIndex: 'options',
            key: 'options',
            render: (o: any) => renderOptions(o)
        },
        {
            title: 'User Default',
            dataIndex: 'userDefault',
            key: 'userDefault',
            render: (status: string) => <StatusPills type={status ? "enabled" : "disabled"} />,
            filters: [
                { text: 'Yes', value: true },
                { text: 'No', value: false }
            ],
            onFilter: (value: any, p: any) => p.status.toLowerCase() === value,
        },
        {
            title: 'Service Accounts',
            dataIndex: 'uid',
            key: 'uid',
            render: (uid: string) => {
                return countPackageIdInServiceAccount(uid)
            }
        }
    ];


    return (
        <SecureWithLogin callbackPath="/nsadmin" role={['nsadmin_packages']}>
            <MotionBox>
                {(!loadingPackages && packages !== null && errorPackages === null && !loadingServiceAccounts && errorServiceAccounts === null && serviceAccounts) &&
                    <Content>
                        <Card
                            title="My Packages"
                        >
                            <Table
                                dataSource={packages}
                                columns={columns}
                                rowKey="uid"
                                scroll={{ x: 'max-content' }}
                                virtual
                                expandable={{
                                    expandedRowRender: (p: Package) => {
                                        return (
                                            <Table
                                                dataSource={p.volumes}
                                                columns={[
                                                    {
                                                        title: 'Engine',
                                                        dataIndex: 'product',
                                                        key: 'product',
                                                        render: (product: string) => {
                                                            const p = products.find((p: Product) => p?.value.toLowerCase() === product?.toLowerCase());
                                                            return (
                                                                <Tag color={p?.color}>{product}</Tag>
                                                            )
                                                        }
                                                    },
                                                    {
                                                        title: 'Collection',
                                                        dataIndex: 'collection',
                                                        key: 'collection'
                                                    },
                                                    {
                                                        title: 'Amount',
                                                        dataIndex: 'amount',
                                                        key: 'amount'
                                                    },
                                                    {
                                                        title: 'Cycle',
                                                        dataIndex: 'cycle',
                                                        key: 'cycle'
                                                    },
                                                    {
                                                        title: 'Options',
                                                        dataIndex: 'options',
                                                        key: 'options',
                                                        render: (o: any) => {
                                                            if (o === null) {
                                                                return "None"
                                                            }
                                                            return o?.join(', ')
                                                        }
                                                    },
                                                    {
                                                        title: 'Usage',
                                                        key: 'usage',
                                                        render: (v: any) => {
                                                            return <VolumeProgress pack={p} volume={v} type="line" />
                                                        }
                                                    }
                                                ]}
                                                rowKey="collection"
                                                pagination={false}
                                            />
                                        )
                                    }
                                }}
                                pagination={{
                                    showSizeChanger: packages.length > 10,
                                    pageSizeOptions: ['10', '20', '30', '40', '50'],
                                    defaultPageSize: 20
                                }}
                            />
                        </Card>
                    </Content>
                }
            </MotionBox>
            {loadingPackages && <LoadingSkeleton />}
            {errorPackages && <ShowError error={errorPackages} />}
        </SecureWithLogin>
    )
}
