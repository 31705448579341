import './reset.css';
import './index.css';
import './assets/scss/common.scss';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ConfigContextProvider from './contexts/ConfigContext';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorPage from './components/Layout/ErrorPage';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ErrorBoundary fallback={<ErrorPage />}>
            <ConfigContextProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ConfigContextProvider>
        </ErrorBoundary>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
