import { Alert, Button, Card, Col, Row, Tag, Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';
import Loading from '../Layout/Loading';
import { WidgetCard } from '../WidgetCard/WidgetCard'
import useClusterStatus from '../../hooks/useClusterStatus';

const FullClusterStatusWidget = () => {

    const { clusterStatus: clusterStatusDev, loading: loadingDev, error: errorDev }: { clusterStatus: any, loading: boolean, error: any } = useClusterStatus('DEV');
    const { clusterStatus: clusterStatusSandbox, loading: loadingSandbox, error: errorSandbox }: { clusterStatus: any, loading: boolean, error: any } = useClusterStatus('SANDBOX');
    const { clusterStatus: clusterStatusTest, loading: loadingTest, error: errorTest }: { clusterStatus: any, loading: boolean, error: any } = useClusterStatus('TEST');
    const { clusterStatus: clusterStatusProd, loading: loadingProd, error: errorProd }: { clusterStatus: any, loading: boolean, error: any } = useClusterStatus('PROD');

    return (
        <WidgetCard className="card cluster-status-widget-card" title="Service Status" extra={<Link to="/cluster-status"><Button >More Details</Button></Link>} bordered={false} >
            {/* <div>
        <Typography.Text><Badge className="site-badge-count-109" count={'DEV'} style={{ backgroundColor: `var(--colorEnv${toTitleCase('DEV')})` }}/>&nbsp;{getClusterStatusFromDev(clusterStatusDev, loadingDev, errorDev)} </Typography.Text>
      </div> */}

            <Row gutter={[24, 24]}>
                <Col flex="1 1 250px">
                    <Card >
                        {
                            (errorDev)
                                ?
                                <Alert message="Error" description={"Unable to get the status on the Dev Environment"} type="error" showIcon />
                                :
                                (loadingDev)
                                    ?
                                    <Loading />
                                    :
                                    getClusterStatusFromEnv(clusterStatusDev, loadingDev, errorDev, 'Dev')
                        }
                    </Card>
                </Col>
                <Col flex="1 1 250px">
                    <Card >
                        {
                            // (errorSandbox)
                            //     ?
                            //     <Alert message="Error" description={"Unable to get the status on the Sandbox Environment"} type="error" showIcon />
                            //     :
                                (loadingSandbox)
                                    ?
                                    <Loading />
                                    :
                                    getClusterStatusFromEnv(clusterStatusSandbox, loadingSandbox, errorSandbox, 'Sandbox')
                        }
                    </Card>
                </Col>
                <Col flex="1 1 250px">
                    <Card >
                        {
                            // (errorTest)
                            //     ?
                            //     <Alert message="Error" description={"Unable to get the status on the Test Environment"} type="error" showIcon />
                            //     :
                                (loadingTest)
                                    ?
                                    <Loading />
                                    :
                                    getClusterStatusFromEnv(clusterStatusTest, loadingTest, errorTest, 'Test')
                        }
                    </Card>
                </Col>
                <Col flex="1 1 250px">
                    <Card >
                        {
                            // (errorProd)
                            //     ?
                            //     <Alert message="Error" description={"Unable to get the status on the Production Environment"} type="error" showIcon />
                            //     :
                                (loadingProd)
                                    ?
                                    <Loading />
                                    : getClusterStatusFromEnv(clusterStatusProd, loadingProd, errorProd, 'Prod')
                        }
                    </Card>
                </Col>
                {/* <Col xs={24} sm={24} md={12} lg={12} xxl={12}><Card>{getClusterStatusFromEnv(clusterStatusSandbox, loadingSandbox, errorSandbox, 'Sandbox')}</Card></Col>
                    <Col xs={24} sm={24} md={12} lg={12} xxl={12}><Card>{getClusterStatusFromEnv(clusterStatusTest, loadingTest, errorTest, 'Test')}</Card></Col>
                    <Col xs={24} sm={24} md={12} lg={12} xxl={12}><Card>{getClusterStatusFromEnv(clusterStatusProd, loadingProd, errorProd, 'Prod')}</Card></Col> */}
            </Row>
        </WidgetCard>
    )
}

const getClusterStatusFromEnv = (clusterStatus: any, loading: boolean, error: any, env: string) => {
    if (loading) return <Loading />
    
    return (<>
        <Row>
            <Col><Typography.Title level={5}><span>{clusterStatus?.message ? <Tag icon={<CheckCircleOutlined />} color="success">Operational</Tag> : <Tag icon={<CloseCircleOutlined />} color="error">Down</Tag>}</span>{env}</Typography.Title></Col>
        </Row>
        <Row>
            <Col><span>{clusterStatus?.apiVersion || 'Error'} - {clusterStatus?.message ? 'Service is up and running' : 'Service is down'}</span></Col>
        </Row></>)
}
export default FullClusterStatusWidget