import "./../../../assets/scss/CreateForms.scss";

import { Button, Card, Form, Input, Space } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Content } from "antd/es/layout/layout";
import LoadingSkeleton from "../../Layout/LoadingSkeleton";
import MotionBox from "../../Layout/Animated/MotionBox";
import { SaveOutlined } from '@ant-design/icons';
import { SecureWithLogin } from "../../../utils/secure";
import { callBackEnd } from "../../../utils/backEndUtils";
import { toast } from "react-toastify";
import { useForm } from "antd/es/form/Form";

// import usePartners from "../../../hooks/usePartners";

export default function FormNamespace() {
    const [form] = useForm();
    const navigate = useNavigate();
    const { namespaceId } = useParams();
    const formMode: string = namespaceId ? 'edit' : 'create';

    const [namespaceStaticTypes, setNamespaceStaticTypes] = useState<string[]>([]);

    const formData: NamespaceFormDataType = {
        name: '',
        label: '',
        emailDomain: '',
        type: null,
        partnerId: null,
    }
    //namespace types static contoller
    useEffect(() => {
        async function getNamespaceStaticTypes() {
            try {
                const response: any = await callBackEnd('portal', 'get', `static/namespace_types`);
                if (Array.isArray(response)) {
                    setNamespaceStaticTypes(response);
                }
            }
            catch (error: any) {
                console.log(error);
            }

        }
        getNamespaceStaticTypes().catch((e: any) => console.log(e));
    }, []);
    //Get the namespace data for edit mode
    useEffect(() => {
        async function getNamespace() {
            try {
                const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}`);
                form.setFieldsValue({
                    label: response.label,
                    name: response.name,
                    emailDomain: response.emailDomain
                });

            } catch (error: any) {
                console.log(error);
            }
        }

        if (formMode === 'edit' && namespaceId) {

            getNamespace().catch((e: any) => console.log(e));
        }
    }, [namespaceId, formMode, form]);



    const handleForm = (values: any) => {

        async function saveNamespace(data: NamespaceFormDataType) {
            try {

                if (formMode === 'edit' && namespaceId) {
                    //edit
                    await callBackEnd('portal', 'PUT', `namespaces/${namespaceId}`, JSON.stringify(data));
                    navigate(`/namespaces/${namespaceId}`);
                }
                else {
                    //create
                    await callBackEnd('portal', 'post', `namespaces`, JSON.stringify(data));
                    navigate(`/namespaces/`);
                }



            } catch (error: any) {
                console.log(error);
                toast.error('Error while creating your namespace');
            }
        }


        formData.label = values.label;
        formData.name = values.name;
        formData.emailDomain = values.emailDomain;
        // formData.partnerId = inputPartner?.current?.value ?? "";
        // formData.partnerId = formData.partnerId === "none" ? null : formData.partnerId;

        saveNamespace(formData).catch((e: any) => console.log(e));

    }

    // const handleDeleteNamespace = (namespaceId: any) => {

    //     //TODO: pas de delete dans le cas d'un service account actif, retourner l'erreur

    //     async function deleteNamespace(id: string) {
    //         try {
    //             await callBackEnd('portal', 'DELETE', `namespaces/${namespaceId}`);
    //             navigate(`/namespaces`);

    //         } catch (error: any) {
    //             console.log(error);
    //         }
    //     }
    //     deleteNamespace(namespaceId).catch((e: any) => console.log(e));
    // }

    // const handleDeleteModalResponse = (response: boolean) => {
    //     if (response) {
    //         setShowDeleteModal(false);
    //         handleDeleteNamespace(namespaceId);
    //     }
    //     else {
    //         setShowDeleteModal(false);
    //     }
    // }

    return (
        <SecureWithLogin callbackPath="/namespaces" role={['crm_namespaces']}>
            <>
                {namespaceStaticTypes.length > 0 ?
                    <MotionBox>
                        <Content>
                            <Card
                                title={formMode === 'edit' ? 'Edit namespace' : 'Create new namespace'}
                            >
                                <Form
                                    labelWrap
                                    form={form}
                                    onFinish={handleForm}
                                    labelCol={{ span: 3 }}
                                    wrapperCol={{ span: 21 }}
                                >
                                    <Form.Item
                                        label='Label'
                                        name='label'
                                        rules={[{ required: true, message: 'Please set your namespace label' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label='Name'
                                        name='name'

                                        rules={[{ required: true, message: 'Please set your namespace name' }]}
                                    >
                                        <Input disabled={formMode === 'edit'} />
                                    </Form.Item>
                                    <Form.Item
                                        label='Email Domain'
                                        name='emailDomain'
                                        rules={[{ required: true, message: 'Please set your email domain' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item wrapperCol={{ offset: 3, span: 21 }}>
                                        <Space size="middle">
                                            <Button type="text" onClick={() => navigate(`/namespaces/${namespaceId}`)}>
                                                Cancel
                                            </Button>
                                            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                                                Save
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </Content>
                    </MotionBox>
                    :
                    <LoadingSkeleton />
                }
                {/* {showDeleteModal && editedNamespace !== null &&
                    <CustomModal secureString={editedNamespace.name} title="Delete namespace" message={`Are you sure you want to delete the namespace ${editedNamespace.label} ? Please type in the name of the namespace "${editedNamespace.name}" as a confirmation.`} callback={handleDeleteModalResponse} />
                } */}
            </>
        </SecureWithLogin>
    )
}
