import '../../assets/scss/ReleaseNotes.scss';

import { Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { HomeOutlined } from '@ant-design/icons';
import LoadingSkeleton from "../Layout/LoadingSkeleton";
import Markdown from 'react-markdown';
import MotionBox from "../Layout/Animated/MotionBox";
import { PageHeader } from '../Layout/header/PageHeader';
import { SecureWithLogin } from "../../utils/secure";
import ShowError from '../Layout/ShowError';
import useReleaseNotes from "../../hooks/useReleaseNotes";
import { useState } from 'react';

export default function ReleaseNotes() {
    const { releaseNotes, loading, error } = useReleaseNotes();
    const [activeTabKey, setActiveTabKey] = useState<string>('0');

    const tablist = releaseNotes.map((releaseNote: { version: string, content: string }, index: number) => {
        return {
            key: index.toString(),
            label: releaseNote.version
        }
    })

    const onTabChange = (key: string) => {
        setActiveTabKey(key);
    };

    return (
        <SecureWithLogin callbackPath="/releaseNotes" role={['portal_release_notes']}>
            {(!loading && error === null && releaseNotes !== null) ?
                <MotionBox>
                    <Content>
                    {/* <SectionBanner title="Release Notes" type="releaseNotes" /> */}
                    <PageHeader
                        title='Release Notes'
                        subtitle='Dashboard'
                        breadcrumbs={[
                            {title:<span><HomeOutlined/>&nbsp;Home</span>,href: '/',},
                            {title:<span>Release notes</span>}]}
                    />
                    <Content style={{ backgroundColor: '#fff', padding:'24px'}}>
                    <Card
                        tabList={tablist}
                        activeTabKey={activeTabKey}
                        onTabChange={onTabChange}
                        className="sectionCard releaseNotes"
                    >
                        <div><Markdown>{releaseNotes[parseInt(activeTabKey)].content}</Markdown></div>
                    </Card>
                    </Content>
                    </Content>
                </MotionBox>
                :
                <>
                    {loading && <LoadingSkeleton />}
                    {error && <ShowError error={error} />}
                </>
            }
        </SecureWithLogin >
    )
}
