import '../../../assets/scss/CreatePackagesAndNamespaces.scss';

import { Button, Card, Checkbox, Col, DatePicker, Flex, Form, Input, Radio, Row, Select, Space, Typography } from 'antd';
import { CloseOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FormOptions from './options/FormOptions';
import MotionBox from '../../Layout/Animated/MotionBox';
import { SecureWithLogin } from '../../../utils/secure';
import { callBackEnd } from '../../../utils/backEndUtils';
import dayjs from 'dayjs';
import { getEnvironments } from '../../../utils/getEnvironments';
import { toast } from 'react-toastify';
import useCollections from '../../../hooks/useCollections';

enum Cycle {
    MONTHLY = "MONTHLY",
    DAILY = "DAILY",
    YEARLY = "YEARLY"
}

export default function FormPackages() {
    const cycles: any[] = [
        { value: Cycle.DAILY, label: 'Daily' },
        { value: Cycle.MONTHLY, label: 'Monthly' },
        { value: Cycle.YEARLY, label: 'Yearly' }
    ];

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { namespaceId, packageId } = useParams();

    const [options, setOptions] = useState<any[]>([]);

    const { collections, loading: collectionsLoading, error: collectionsError }: { collections: Collection[], loading: boolean, error: any } = useCollections();

    const formMode: string = packageId !== undefined ? 'edit' : 'create';
    const [pkg, setPkg] = useState<Package | null>(null);

    const [staticPackageTypes, setStaticPackageTypes] = useState<PackageType[] | null>(null);


    const formData: PackageFormDataType = {
        name: '',
        description: '',
        options: [],
        environment: '',
        type:'USER',
        expiration: '',
        status: '',
        volumes: [],
        limit: 'SOFT',
        userDefault: false
    }
    useEffect(() => {
        async function getStaticPackageTypes() {
            try {
                const response: any = await callBackEnd('portal', 'get', "static/package_types");
                if (response?.length > 0) {
                    setStaticPackageTypes(response);
                }
                console.log("response", response);
            } catch (error: any) {
                toast.error('An error occured while loading package types');
            }
        }
        getStaticPackageTypes().catch((error) => { console.log(error) });
    }, []);

    useEffect(() => {

        //Used for edit mode
        async function getPackage() {
            try {
                if (packageId) {
                    const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/packages/${packageId}`)
                        .catch((e: any) => { throw new Error(e) });
                    console.log("🚀 ~ getPackage ~ response:", response);
                    setPkg(response);

                    setOptions(response.options);
                    
                    form.setFieldsValue({
                        name: response.name,
                        description: response.description,
                        environment: response.environment,
                        type: response.type,
                        expiration: response.expiration === null ? null : dayjs(response.expiration),
                        status: response.status,
                        userDefault: response.userDefault,
                        limit: response.limit,
                        volumes: response.volumes?.map((volume: any) => {
                            return {
                                product: volume.product,
                                amount: volume.amount,
                                cycle: volume.cycle,
                                collection: volume.collection,
                                options: volume.options,
                                uid: volume.uid,
                                ...(volume.cycle === "YEARLY" && { anniversary: volume.anniversary })
                            }
                        })
                    })
                }
            } catch (error: any) {
                toast.error('An error occured while loading the package');
            }
        }

        if (formMode === 'edit' && packageId !== undefined) {
            getPackage().catch((error) => { console.log(error) });
        }

    }, [packageId, namespaceId, formMode, form]);

    /**
     * Handle options callback from component
     */
    const handleOptions = useCallback((options: any) => {
        setOptions(options);
    }, []);

    const hasCollectionOptions = (collection: string) => {
        const collectionOptions = collections.find((element: any) => collection === element.name)?.options;
        return collectionOptions !== null;
    };

    /**
     * Handle form submit
    */
    const handleForm = (values: any) => {
        async function savePackage(data: PackageFormDataType) {
            try {
                //Edit
                if (formMode === 'edit' && packageId !== undefined) {
                    await callBackEnd('portal', 'put', `namespaces/${namespaceId}/packages/${packageId}`, JSON.stringify(data));
                }
                else {
                    //Create
                    await callBackEnd('portal', 'post', `namespaces/${namespaceId}/packages`, JSON.stringify(data));
                }
                navigate(`/namespaces/${namespaceId}`);
            } catch (error: any) {
                console.log(error);
            }
        }

        if (namespaceId !== undefined) {
            formData.name = values.name;
            formData.description = values.description;
            formData.options = options;
            formData.environment = values.environment;
            formData.type = values.type;
            formData.expiration = values.expiration ? dayjs(values.expiration).format('YYYY-MM-DD') : null;
            formData.status = values.status;
            formData.volumes = values.volumes?.map((volume: any) => {
                return {
                    product: volume.product,
                    amount: Number(volume.amount),
                    cycle: volume.cycle,
                    collection: volume.collection,
                    ...(volume.uid && { uid: volume.uid }),
                    ...(hasCollectionOptions(volume.collection) && { options: volume.options }),
                    ...(volume.cycle === "YEARLY" && { anniversary: volume.anniversary })
                }
            });

            formData.limit = values.limit;
            formData.userDefault = values.userDefault;
            console.log("🚀 ~ handleForm ~ formData:", formData);

            savePackage(formData).catch((error) => { console.log(error) });
        }
    }

    const isCollectionDisabled = (currentFieldIndex: number, currentProduct: string, collection: string) => {
        const volumes = form.getFieldValue('volumes').filter((volume: any) => volume !== undefined);

        return volumes.some((volume: any, index: number) => {
            return index !== currentFieldIndex && volume.product === currentProduct && volume.collection === collection;
        });
    };

    return (
        <SecureWithLogin callbackPath="/namespaces" role={['crm_packages']}>
            {(!collectionsLoading && collectionsError === null && staticPackageTypes?.length) &&
                <MotionBox>
                    <Card
                        title={(formMode === 'edit') ? 'Edit ' + pkg?.name : 'Create a new package'}
                    >
                        <Form
                            labelWrap
                            form={form}
                            onFinish={handleForm}
                            onValuesChange={() => form.validateFields(['volumes'])} // Trigger re-render on values change
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 21 }}
                        >
                            <Form.Item
                                label='Name'
                                name='name'
                                rules={[{ required: true, message: 'Please set your package name' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label='Description'
                                name='description'
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                            <Form.Item
                                label='Environment'
                                name='environment'
                                rules={[{ required: true, message: 'Please set your environment' }]}
                            // initialValue={'SANDBOX'}
                            >
                                <Radio.Group
                                    options={getEnvironments()?.map((e: any) => {
                                        return { label: e.label, value: e.name }
                                    })}
                                    disabled={formMode === 'edit'}
                                />
                            </Form.Item>
                            <Form.Item
                                label='Package Type'
                                name='type'
                                rules={[{ required: true, message: 'Please set your package type' }]}
                            >
                                <Select
                                    options={staticPackageTypes?.map((pt: PackageType) => {
                                        return { label: pt.replace(/_/g, ' '), value: pt }
                                    })}
                                />
                            </Form.Item>
                            <Row>
                                <Col md={{ span: 21, offset: 3 }} sm={{ span: 24, offset: 0 }}>
                                    <Form.List
                                        name="volumes"
                                        rules={[
                                            {
                                                validator: async (_, volumes) => {
                                                    if (!volumes || volumes.length < 1) {
                                                        return Promise.reject(new Error('At least one volume is required'));
                                                    }
                                                },
                                            },
                                        ]}
                                    >
                                        {(fields, { add, remove }) => (
                                            <Flex vertical gap="middle">
                                                {
                                                    fields?.map(((field) => (
                                                        <Card
                                                            size="small"
                                                            title={`Volume ${field.name + 1}`}
                                                            key={field.key}
                                                            type="inner"
                                                            extra={
                                                                <Space size="large">
                                                                    {form.getFieldValue(['volumes', field.name, 'uid']) && <Typography.Text type="secondary">UID: {form.getFieldValue(['volumes', field.name, 'uid'])}</Typography.Text>}
                                                                    <CloseOutlined
                                                                        onClick={() => {
                                                                            remove(field.name);
                                                                        }}
                                                                    />
                                                                </Space>
                                                            }
                                                        >
                                                            <Form.Item
                                                                {...field}
                                                                label="Uid"
                                                                name={[field.name, 'uid']}
                                                                hidden
                                                            >
                                                                <Input disabled />
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...field}
                                                                label="Engine"
                                                                name={[field.name, 'product']}
                                                                rules={[{ required: true, message: 'Please set at least one engine' }]}
                                                            >
                                                                <Radio.Group
                                                                    options={[
                                                                        { label: 'JETSCAN', value: 'JETSCAN' },
                                                                        { label: 'JETFLOW', value: 'JETFLOW' },
                                                                    ]}
                                                                    disabled={form.getFieldValue(['volumes', field.name, 'uid'])} //disabled if a UID is set (edit mode)
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label='Collection'
                                                                name={[field.name, 'collection']}
                                                                rules={[{ required: true, message: 'Please set a collection' }]}
                                                            >
                                                                <Select
                                                                    placeholder="Select a collection"
                                                                    options={
                                                                        collections?.map((c: any) => {
                                                                            const currentProduct = form.getFieldValue(['volumes', field.name, 'product']);
                                                                            return { value: c.name, label: c.labe, disabled: isCollectionDisabled(field.name, currentProduct, c.name) }
                                                                        })
                                                                    }
                                                                    allowClear={false}
                                                                    defaultActiveFirstOption={true}
                                                                    disabled={form.getFieldValue(['volumes', field.name, 'uid'])}//disabled if a UID is set (edit mode)
                                                                />
                                                                {/* } */}
                                                            </Form.Item>
                                                            <Form.Item shouldUpdate={true} noStyle>
                                                                {({ getFieldValue }) => {
                                                                    const collection = getFieldValue(["volumes", field.name, "collection"]);
                                                                    const isValidCollection = collection !== 'undefined' && collections.some((element: any) => collection === element.name);
                                                                    const hasOptions: boolean = collections.find((element: any) => collection === element.name)?.options !== null;
                                                                    const collectionOptions: any = collections.find((element: any) => collection === element.name)?.options;
                                                                    const requiredOptions: any = collectionOptions?.filter((option: any) => option.required)?.map((option: any) => option.name) || [];

                                                                    return hasOptions && isValidCollection ? (
                                                                        <Form.Item
                                                                            label={`Collection Options for ${collection}`}
                                                                            name={[field.name, 'options']}
                                                                            initialValue={requiredOptions}
                                                                        >
                                                                            <Checkbox.Group options={collectionOptions?.map((co: any) => {
                                                                                return { label: co.label, value: co.name, key: co.name, disabled: co.required };
                                                                            })}
                                                                            />
                                                                        </Form.Item>
                                                                    ) : null;
                                                                }}
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...field}
                                                                label="Amount"
                                                                name={[field.name, 'amount']}
                                                                rules={[{ required: true, message: 'Please set a collection' }]}
                                                            >
                                                                <Input type="number" min={1} />
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...field}
                                                                label="Cycle"
                                                                name={[field.name, 'cycle']}
                                                                rules={[{ required: true, message: 'Please set your volume cycle' }]}
                                                            >
                                                                <Select options={cycles} allowClear={false} />
                                                            </Form.Item>
                                                            <Form.Item shouldUpdate={true} noStyle>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(["volumes", field.name, "cycle"]) === 'YEARLY' && (
                                                                        <Form.Item
                                                                            {...field}
                                                                            label='Anniversary date'
                                                                            tooltip="Yearly volumes only, daily resets at 12pm GMT and monthly resets on the 1st day of the month"
                                                                            name={[field.name, 'anniversary']}
                                                                            dependencies={[["volumes", field.name, "cycle"]]}
                                                                            rules={[
                                                                                // { required: true, message: 'Please set your expiration mode' },
                                                                                ({ getFieldValue }) => ({
                                                                                    validator(_, value) {
                                                                                        const cycle: string = getFieldValue(["volumes", field.name, "cycle"]);
                                                                                        if (cycle === 'YEARLY' && value === undefined) {
                                                                                            return Promise.reject(new Error('Please set an anniversary date for yearly packages'));
                                                                                        }
                                                                                        return Promise.resolve();
                                                                                    },
                                                                                }),
                                                                            ]}
                                                                        >
                                                                            <DatePicker />
                                                                        </Form.Item>
                                                                    )
                                                                }
                                                            </Form.Item>
                                                        </Card>
                                                    ))
                                                    )}
                                                < Form.Item >
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => add()}
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Add volume
                                                    </Button>
                                                </Form.Item>
                                            </Flex>
                                        )}
                                    </Form.List>
                                </Col>
                            </Row>
                            <Form.Item
                                label='Options'
                                name='options'
                            >
                                <FormOptions handleOptions={handleOptions} defaultValues={(formMode === 'edit' && pkg !== null) ? pkg?.options : null} />
                            </Form.Item>
                            <Form.Item
                                label='Volume Limit'
                                name='limit'
                                rules={[{ required: true, message: 'Please set your volume limit' }]}
                            >
                                <Select
                                    options={[
                                        { label: 'Soft', value: 'SOFT' },
                                        { label: 'Hard', value: 'HARD' },
                                    ]}
                                    allowClear={false}
                                />
                            </Form.Item>
                            <Form.Item
                                label='Expiration date'
                                tooltip="Exipration of the entire package (all volumes)"
                                name='expiration'
                                dependencies={['expirationMode']}
                                rules={[
                                    // { required: true, message: 'Please set your expiration mode' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (getFieldValue('expirationMode') === 'limited' && value === undefined) {
                                                return Promise.reject(new Error('Please set your expiration date'));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <DatePicker />
                            </Form.Item>
                            <Form.Item
                                label='Status'
                                name='status'
                                rules={[{ required: true, message: 'Please set your status' }]}
                            >
                                <Radio.Group
                                    options={[
                                        { label: 'Enable package', value: 'ENABLED' },
                                        { label: 'Disable package', value: 'DISABLED' },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item
                                label='Default for new users'
                                name='userDefault'
                                tooltip='Enable to assign to new users by default'
                                rules={[{ required: true, message: 'Please a value' }]}
                            >
                                <Radio.Group
                                    options={[
                                        { label: 'Yes', value: true },
                                        { label: 'No', value: false },
                                    ]}
                                />
                            </Form.Item>


                            <Form.Item wrapperCol={{ offset: 3, span: 21 }}>
                                <Space size="middle">
                                    <Button type="text" onClick={() => navigate(`/namespaces/${namespaceId}`)}>
                                        Cancel
                                    </Button>
                                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                                        Save
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Card>
                </MotionBox>
            }
        </SecureWithLogin >
    )
}
