import { Button, Card, Descriptions, Divider, Table } from 'antd';
import { Link, useNavigate, useParams } from "react-router-dom";
import { SecureWithLogin, ShowIfAccess, useOidcUser } from '../../../../utils/secure';
import { faUserLock, faUserSlash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

import CustomModal from '../../../crm/common/CustomModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from '../../../Layout/Loading';
import Moment from 'react-moment';
import ShowError from '../../../Layout/ShowError';
import { callBackEnd } from '../../../../utils/backEndUtils';
import { toast } from 'react-toastify';

export default function NSAUser() {

    const { userId, namespaceId } = useParams();
    const navigate = useNavigate();

    const [loadingUser, setLoadingUser] = useState<boolean>(true);
    const [errorUser, setErrorUser] = useState<any>(null);
    const [user, setUser] = useState<UserProfile | null>(null);
    const [loadingRoles, setLoadingRoles] = useState<boolean>(true);
    const [errorRoles, setErrorRoles] = useState<any>(null);
    const [roles, setRoles] = useState<Role[] | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

    const [namespaceDetails, setNamespaceDetails] = useState<Namespace | null>(null);

    const { oidcUser }: { oidcUser: any } = useOidcUser();
    const isRestricted: boolean = oidcUser?.jetUserId === user?.uid; //user can't edit himself

    

    useEffect(() => {
        async function getUser() {
            try {
                setLoadingUser(true);
                setLoadingRoles(true);
                const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/users/${userId}`);
                setUser(response);

                if (namespaceId) {
                    //Namespace details
                    const namespaceResponse: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}`);
                    setNamespaceDetails(namespaceResponse);
                    //Roles
                    const responseRoles: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/users/${userId}/roles`).catch((e: any) => setErrorRoles(e));;
                    setRoles(responseRoles);
                    setLoadingRoles(false);
                }

            } catch (error: any) {
                setErrorUser(error);
            } finally {
                setLoadingUser(false);
            }
        }
        getUser().catch((e: any) => console.log(e));
    }, [userId, namespaceId]);

    const handleRemoveModalResponse = (response: boolean) => {
        if (response && !isRestricted) {
            setShowRemoveModal(false);

            async function removeFromNamespace() {
                try {
                    await callBackEnd('portal', 'DELETE', `namespaces/${namespaceId}/users/${userId}`);
                } catch (error: any) {
                    setErrorUser(error);
                } finally {
                    toast.success(`User ${user?.email} has been removed from the namespace`);
                    if (namespaceId) {
                        navigate(`/nsadmin/users`)
                    }
                }
            }
            removeFromNamespace().catch((e: any) => console.log(e));
        }
        else {
            setShowRemoveModal(false);
        }
    }

    return (
        <SecureWithLogin callbackPath="/nsadmin" role={['nsadmin_users']}>
            {(errorUser === null && !loadingUser && user !== null) ?
                <>
                    <Card
                        title={<>User: {user.email}</>}
                        className="users"
                        extra={
                            <Button type="primary" danger onClick={() => setShowRemoveModal(true)} icon={<FontAwesomeIcon icon={faUserSlash}></FontAwesomeIcon>} disabled={isRestricted}>
                                Remove user from namespace
                            </Button>
                        }
                    >
                        <Descriptions>
                            <Descriptions.Item label="Firstname">{user?.firstName}</Descriptions.Item>
                            <Descriptions.Item label="Lastname">{user?.lastName}</Descriptions.Item>
                            <Descriptions.Item label="E-Mail">{user?.email}</Descriptions.Item>
                            {namespaceDetails &&
                                <Descriptions.Item label="Namespace">
                                    {namespaceDetails.label} ({namespaceDetails.name})
                                </Descriptions.Item>
                            }
                            <Descriptions.Item label="Last Login"><Moment date={user?.lastLogin} /></Descriptions.Item>
                        </Descriptions>
                        <Divider />
                        {(errorRoles === null && !loadingRoles && roles !== null) ?
                            <Card
                                title="User roles and available features"
                                type="inner"
                                extra={
                                    <ShowIfAccess role={['nsadmin_users']}>
                                        <Link to={`/nsadmin/${user.namespaceId}/users/${user.uid}/roles`}>
                                            <Button type="primary" icon={<FontAwesomeIcon icon={faUserLock}></FontAwesomeIcon>} disabled={isRestricted}>
                                                Edit user roles
                                            </Button>
                                        </Link>
                                    </ShowIfAccess>
                                }
                            >
                                {roles.length > 0 ?
                                    <Table
                                        pagination={false}
                                        dataSource={roles}
                                        rowKey="uid"
                                        scroll={{ x: 'max-content' }}
                                        virtual
                                        columns={[
                                            {
                                                title: 'Role',
                                                dataIndex: 'label',
                                                key: 'label',
                                            },
                                            {
                                                title: 'Environments',
                                                dataIndex: 'environments',
                                                key: 'environments',
                                                render: (value: string[], r: any) => {
                                                    if (value?.length > 0) {
                                                        return value.join(',');
                                                    }
                                                    else {
                                                        return 'Feature not environment-related';
                                                    }
                                                }
                                            }
                                        ]}
                                    />
                                    :
                                    <p>No roles found</p>
                                }
                            </Card>
                            :
                            <>
                                {loadingRoles && <Loading />}
                                {errorRoles && <ShowError error={errorRoles} />}
                            </>
                        }
                    </Card>

                    {showRemoveModal && user !== null &&
                        <CustomModal secureString={user.email} title="Remove user from namespace" message={`Are you sure you want to remove the user ${user.email} from this Namespace ? Please type in the name of the user "${user.email}" as a confirmation.`} callback={handleRemoveModalResponse} />
                    }

                </>
                :
                <>
                    {loadingUser && <Loading />}
                    {errorUser && <ShowError error={errorUser} />}
                </>
            }
        </SecureWithLogin >
    )
}
