import { Button, Card, Checkbox, Form, Space, Typography } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SecureOwnEdits, SecureWithLogin } from "../../../../utils/secure";
import { useEffect, useState } from "react";

import MotionBox from "../../../Layout/Animated/MotionBox";
import { SaveOutlined } from '@ant-design/icons';
import { callBackEnd } from "../../../../utils/backEndUtils";
import { useForm } from "antd/es/form/Form";

export default function NSAUserFormRoles() {

    const navigate = useNavigate();
    const [form] = useForm();
    const { userId, namespaceId } = useParams();


    const [staticRoles, setStaticRoles] = useState<StaticRole[] | null>(null);
    const [errorStaticRoles, setErrorStaticRoles] = useState<any>(null);
    const [loadingStaticRoles, setLoadingStaticRoles] = useState<boolean>(true);

    const [userInfos, setUserInfos] = useState<any>(null);


    useEffect(() => {
        async function getUserInfos() {
            try {
                const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/users/${userId}`);
                setUserInfos(response);
            } catch (error: any) {
                console.log(error);
            }
        }
        async function getUserRoles() {

            try {
                const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/users/${userId}/roles`);
                form.setFieldsValue(
                    response.reduce((acc: any, role: Role) => {
                        if (role.environments === null) {
                            acc[role.name] = ['All'];
                        }
                        else {
                            acc[role.name] = role.environments;
                        }
                        return acc;
                    }
                        , {})
                );
            } catch (error: any) {
                console.log(error);
            }
        }
        async function getStaticRoles() {
            try {
                const response: any = await callBackEnd('portal', 'get', `static/roles`);
                response.sort((a: StaticRole, b: StaticRole) => {
                    if (a.category < b.category) {
                        return -1;
                    }
                    if (a.category > b.category) {
                        return 1;
                    }
                    return 0;
                });
                setStaticRoles(response);
            } catch (error: any) {
                setErrorStaticRoles(error);
            } finally {
                setLoadingStaticRoles(false);
            }
        }
        getStaticRoles().catch((e: any) => console.log(e));
        getUserRoles().catch((e: any) => console.log(e));
        getUserInfos().catch((e: any) => console.log(e));
    }, [userId, namespaceId, form]);

    const handleSubmit = async (values: any) => {

        const formValues = form.getFieldsValue();
        const rolesArray = Object.keys(formValues).map((roleName) => {
            if (formValues[roleName]?.length > 0) {
                if (formValues[roleName].length === 1 && formValues[roleName][0] === 'All') {
                    return { name: roleName };
                }
                return {
                    name: roleName,
                    environments: formValues[roleName],
                };
            }
            return null;
        });
        console.log(JSON.stringify(rolesArray.filter(n => n)));
        const response: any = await callBackEnd('portal', 'post', `namespaces/${namespaceId}/users/${userId}/roles`, JSON.stringify(rolesArray.filter(n => n)));
        console.log("🚀 ~ handleSubmit ~ response:", response);
        navigate(`/nsadmin/${namespaceId}/users/${userId}`);
    }


    return (
        <SecureWithLogin callbackPath="/users" role={['nsadmin_home']}>
            {userId &&
                <SecureOwnEdits userId={userId}>
                    <MotionBox>
                        <Card
                            title={<>
                                Edit roles
                                {userInfos !== null && <Typography.Text type="secondary"> for {userInfos.email}</Typography.Text>}
                            </>}
                        >
                            <Form
                                labelWrap
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                onFinish={handleSubmit}
                                form={form}
                            >
                                {(staticRoles !== null && !loadingStaticRoles && errorStaticRoles === null) &&
                                    staticRoles.map((role: StaticRole) => {
                                        return (
                                            <Form.Item
                                                key={role.name}
                                                label={<>{role.label} <Typography.Text type={role.category === "NETERIUM" ? "danger" : "secondary"}>({role.category})</Typography.Text></>}
                                                name={role.name}
                                                tooltip={role.category === "NETERIUM" && 'This function gives access to the "Access & Enablement" section. It can only be activated for users already present in the Neterium Namespace.'}
                                            >
                                                <Checkbox.Group
                                                    options={role.envs === null ? ['All'] : role.envs}
                                                />
                                            </Form.Item>
                                        )
                                    })
                                }
                                <Form.Item wrapperCol={{ offset: 3, span: 21 }}>
                                    <Space size="middle">
                                        <Link to={`/nsadmin/${namespaceId}/users/${userId}`}>
                                            <Button type="text">
                                                Cancel
                                            </Button>
                                        </Link>
                                        <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                                            Save
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </Card>
                    </MotionBox>
                </SecureOwnEdits>
            }
        </SecureWithLogin>
    )
}
