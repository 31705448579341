import '../../../../assets/scss/ServiceAccountsList.scss';

import { Button, Card, Descriptions, Flex, Input, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { faArchive, faCheck, faCirclePlus, faCog, faCopy, faLock, faPenToSquare, faPlay, faStop, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useContext, useEffect, useState } from "react";

import { Content } from 'antd/es/layout/layout';
import CustomModal from '../../../crm/common/CustomModal';
import { EnvContext } from '../../../../contexts/EnvironmentContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { SecureWithLogin } from '../../../../utils/secure';
import VolumeProgress from '../../../Layout/VolumeProgress';
import { callBackEnd } from "../../../../utils/backEndUtils";
import { toTitleCase } from '../../../../utils/stringUtils';
import useAllEnvironments from '../../../../hooks/useAllEnvironments';
import useAllProducts from '../../../../hooks/useAllProducts';
import useNamespaceById from '../../../../hooks/useNamespaceById';

export default function ServiceAccountList(props: any) {
    //Modals
    const [editedServiceAccount, setEditedServiceAccount] = useState<ServiceAccount | null>(null);
    const [showActivateModal, setShowActivateModal] = useState<boolean>(false);
    const [showDeactivateModal, setShowDeactivateModal] = useState<boolean>(false);
    const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);

    const [search, setSearch] = useState<string>('');
    const { namespaceId } = props;
    const [pkg, setPkg] = useState<Package[]>([]);
    const [loadingPkg, setLoadingPkg] = useState<boolean>(true);
    const [errorPkg, setErrorPkg] = useState<any>(null);

    const [loadingServiceAccounts, setLoadingServiceAccounts] = useState<boolean>(true);
    const [errorServiceAccounts, setErrorServiceAccounts] = useState<any>(null);
    const [serviceAccounts, setServiceAccounts] = useState<ServiceAccount[] | null>(null);
    const [filteredServiceAccounts, setFilteredServiceAccounts] = useState<any[] | null | undefined>(null);

    const env: string = useContext(EnvContext);
    const { products } = useAllProducts();

    const [showArchived, setShowArchived] = useState<boolean>(false);

    const { getNamespacePackages,getNamespaceServiceAccounts } = useNamespaceById();

    const { allEnv } = useAllEnvironments();

    const getServiceAccounts = useCallback(async () => {
        try {
            setLoadingServiceAccounts(true);
            const response: any = await getNamespaceServiceAccounts().catch((e: any) => console.log(e));
            setServiceAccounts(response);


        } catch (error: any) {
            setErrorServiceAccounts(error);
        } finally {
            setLoadingServiceAccounts(false);
        }
    }, [getNamespaceServiceAccounts]);

    useEffect(() => {
        //Used for edit mode
        async function getPackages() {
            try {
                setLoadingPkg(true);

                const response: any = await getNamespacePackages({ withVolumes: true }).catch((e: any) => console.log(e));
                setPkg(response);

            } catch (error: any) {
                setErrorPkg(error);

            } finally {
                setLoadingPkg(false);
            }
        }
        if (namespaceId !== "") {
            getServiceAccounts().catch((error) => { console.log(error) });
            getPackages().catch((error) => { console.log(error) });
        }

    }, [namespaceId, getServiceAccounts, getNamespacePackages]);

    useEffect(() => {
        const serviceAccountFiltered = serviceAccounts?.filter((sa: any) => {
            if (search === '') return sa;
            else if (sa?.name.toLowerCase().includes(search.toLowerCase())) return sa;
            else return null;
        })

        setFilteredServiceAccounts(serviceAccountFiltered);

    }, [search, serviceAccounts])

    const showSAIcons = (status: ServiceAccountStatus) => {
        let icon: any = null;
        switch (status) {
            case "ACTIVE":
                icon = faCheck;
                break;
            case "INACTIVE":
                icon = faTimes;
                break;
            case "ARCHIVED":
                icon = faArchive;
                break;
        }

        return (
            <FontAwesomeIcon icon={icon} />
        )
    }

    const showPackage = (packageId: string, prop: any) => {
        if (pkg !== null) {
            const pkgFound = pkg.find((pkg: Package) => pkg.uid === packageId);
            if (pkgFound !== undefined) {
                return pkgFound[prop as keyof Package]
            }
            else {
                return null;
            }
        }
        return null;
    }
    //Modals
    const handleActivateModalResponse = (response: boolean) => {
        if (editedServiceAccount?.uid !== undefined) {
            if (response) {
                setShowActivateModal(false);
                handleChangeStatus(editedServiceAccount?.uid, 'ACTIVE');
            }
            else {
                setShowActivateModal(false);
            }
        }
    }
    const handleDeactivateModalResponse = (response: boolean) => {
        if (editedServiceAccount?.uid !== undefined) {
            if (response) {
                setShowDeactivateModal(false);
                handleChangeStatus(editedServiceAccount?.uid, 'INACTIVE');
            }
            else {
                setShowDeactivateModal(false);
            }
        }
    }
    const handleArchiveModalResponse = (response: boolean) => {
        if (editedServiceAccount?.uid !== undefined) {
            if (response) {
                setShowArchiveModal(false);
                handleChangeStatus(editedServiceAccount?.uid, 'ARCHIVED');
            }
            else {
                setShowArchiveModal(false);
            }
        }
    }
    const handleDuplicateSa = async (uid: string) => {
        try {
            await callBackEnd('portal', 'post', `namespaces/${namespaceId}/service-accounts/${uid}/clone`);
        } catch (error: any) {
            console.log(error);
        } finally {
            getServiceAccounts().catch((error) => { console.log(error) });
        }

    }
    const handleChangeStatus = async (uid: string, status: string) => {
        try {
            await callBackEnd('portal', 'put', `namespaces/${namespaceId}/service-accounts/${uid}/status/${status}`);
        } catch (error: any) {
            console.log(error);
        } finally {
            getServiceAccounts().catch((error) => { console.log(error) });
        }

    }

    const columns = [
        {
            title: 'Engine',
            dataIndex: 'product',
            key: 'product',
            render: (product: string) => {
                const p = products.find((p: Product) => p?.value.toLowerCase() === product?.toLowerCase());
                return (
                    <Tag color={p?.color}>{product}</Tag>
                )
            }
        },
        {
            title: 'Collection',
            dataIndex: 'collection',
            key: 'collection',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Cycle',
            dataIndex: 'cycle',
            key: 'cycle',
        },
        {
            title: 'Usage',
            key: 'usage',
            render: (v: any) => {
                //find package that has a volumes with the right volume.uid
                const p: any = pkg.find((pkg: Package) => pkg.volumes?.find((volume: Volume) => volume.uid === v.uid));
                if (p === undefined) return null;

                return <VolumeProgress pack={p} volume={v} type="line" />
            }
        }
    ];


    const renderServiceAccount = (serviceAccount: ServiceAccount, displayClass: string) => {
        const packageVolumesTable: any = (packageId: string) => {
            const packageFound = pkg.find((pkg: Package) => pkg.uid === packageId);

            if (packageFound === undefined && packageFound) return null;
            if (packageFound?.hasOwnProperty('volumes')) {
                return packageFound?.volumes?.map((volume: any) => {
                    return volume
                });
            }
            else {
                return null;
            }
        };


        return (

            <Card
                title={serviceAccount.name}
                className={`serviceAccount ${displayClass}`}
                key={serviceAccount.uid}
                type="inner"
                extra={
                    <span className={`serviceAccountPill ${serviceAccount?.status.toLowerCase()}`}>
                        {showSAIcons(serviceAccount.status)}{' '}
                        {(serviceAccount.status === "INACTIVE" && !serviceAccount.hasOwnProperty('authenticationType')) ?
                            toTitleCase(`${serviceAccount.status} (No authentication method set)`)
                            :
                            toTitleCase(serviceAccount.status)
                        }
                    </span>
                }
                actions={[
                    <>
                        {serviceAccount?.authenticationType ?
                            <>
                                {(serviceAccount.status === "INACTIVE") &&
                                    <Button danger onClick={(e: any) => {
                                        e.preventDefault();
                                        setEditedServiceAccount(serviceAccount)
                                        setShowActivateModal(true)
                                    }}
                                        icon={<FontAwesomeIcon icon={faPlay} />}
                                    >
                                        Activate
                                    </Button>
                                }
                                {(serviceAccount.status === "ACTIVE") &&
                                    <Button danger onClick={(e: any) => {
                                        e.preventDefault();
                                        setEditedServiceAccount(serviceAccount)
                                        setShowDeactivateModal(true)
                                    }}
                                        icon={<FontAwesomeIcon icon={faStop} />}
                                    >
                                        Deactivate
                                    </Button>
                                }
                            </>
                            : <Button disabled type="text" icon={<FontAwesomeIcon icon={faLock} />}>Service Account locked</Button>}
                    </>,
                    <>
                        {(serviceAccount.status !== "ARCHIVED") &&
                            <Link to={`/nsadmin/${namespaceId}/service-accounts/${serviceAccount.uid}`}>
                                <Button type="text" data-text="Edit" icon={<FontAwesomeIcon icon={faPenToSquare} />}>
                                    Edit Name & Description
                                </Button>
                            </Link>
                        }
                    </>,
                    <Button type="text" key="link" icon={<FontAwesomeIcon icon={faCopy} />} onClick={(e: any) => { handleDuplicateSa(serviceAccount.uid) }}>
                        Duplicate
                    </Button>,
                    <>
                        {(serviceAccount.status === "INACTIVE") ?
                            <Button type="text" icon={<FontAwesomeIcon icon={faArchive} />} onClick={(e: any) => {
                                e.preventDefault();
                                setEditedServiceAccount(serviceAccount)
                                setShowArchiveModal(true)
                            }}>
                                Archive
                            </Button>
                            : <Button disabled type="text" icon={<FontAwesomeIcon icon={faLock} />}>Can not archive while active</Button>}
                    </>

                ]}
            >
                <Flex vertical gap='middle'>
                    <Descriptions title="Service Account">
                        <Descriptions.Item label="Description">{serviceAccount.description}</Descriptions.Item>
                        {/* <Descriptions.Item label="Client ID">{serviceAccount.uid}</Descriptions.Item> */}
                        <Descriptions.Item label="Authentication Type">
                            {(serviceAccount?.authenticationType) ?
                                <Tag color="green">{serviceAccount?.authenticationType}</Tag>
                                : <Space size="small">
                                    <Tag color="red">None</Tag>
                                    {(serviceAccount.status !== "ARCHIVED") &&
                                        // <ShowIfAccess authenticated={true} role={['nsadmin_service_account']}>
                                            <Link to={`/nsadmin/${namespaceId}/service-accounts/${serviceAccount.uid}/authentication`}>
                                                <Button type="primary" size="small" icon={<FontAwesomeIcon icon={faLock} />}>
                                                    Set Authentication
                                                </Button>
                                            </Link>
                                        // </ShowIfAccess>
                                    }
                                </Space>}
                        </Descriptions.Item>
                        {serviceAccount?.updated &&
                            <Descriptions.Item label="Last Update">{serviceAccount?.updated}</Descriptions.Item>
                        }
                    </Descriptions>
                    <Descriptions title="Package Informations">
                        <Descriptions.Item label="Name">
                            <Tooltip title="Click to view the Package details">
                                <Link to={`/nsadmin/packages`}>
                                    {showPackage(serviceAccount.packageId, 'name')}
                                </Link>
                            </Tooltip>
                        </Descriptions.Item>
                        <Descriptions.Item label="Environment">
                            {allEnv.map((e: any) => {
                                if (e.name === serviceAccount.environment) {
                                    return <Tag key={e.name} color={e.color}>{e.label}</Tag>
                                }
                                return null;
                            }
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Expiration date">
                            {showPackage(serviceAccount.packageId, 'expiration') ? showPackage(serviceAccount.packageId, 'expiration') : 'No expiration date'}
                        </Descriptions.Item>


                    </Descriptions>
                    <Typography.Title level={5}>Package Volumes</Typography.Title>
                    <Table size='small' dataSource={packageVolumesTable(serviceAccount.packageId)} columns={columns} pagination={false} scroll={{ x: 'max-content' }} />
                </Flex>
            </Card>
        )
    }

    return (
        <SecureWithLogin callbackPath="/nsadmin/service-accounts" role={['nsadmin_service_accounts']}>
            <Content>
                <Card
                    title={<><FontAwesomeIcon icon={faCog}></FontAwesomeIcon>{' '} My Service Account</>}
                    className="sectionCard"
                    extra={
                        <Space size="middle">
                            <Link to={`/nsadmin/${namespaceId}/service-accounts/create`}>
                                <Button type="primary" icon={<FontAwesomeIcon icon={faCirclePlus}></FontAwesomeIcon>}>
                                    Create new Service Account
                                </Button>
                            </Link>
                            <Button type="primary" onClick={() => setShowArchived(!showArchived)} icon={showArchived ? <EyeInvisibleOutlined /> : <EyeOutlined />}>
                                {showArchived ? "Hide" : "Show"} archived
                            </Button>
                            <Input type="text" placeholder="Search" className="search" name="search" onChange={(e: any) => setSearch(e.target.value)} />
                        </Space>
                    }
                >
                    {(serviceAccounts !== null && serviceAccounts.length > 0 && filteredServiceAccounts !== undefined && filteredServiceAccounts !== null && filteredServiceAccounts.length > 0 && errorServiceAccounts === null && !loadingServiceAccounts && !loadingPkg && errorPkg === null) ?
                        <Flex vertical gap="large">
                            <div className="saList">
                                <Flex vertical gap="large">
                                    {
                                        filteredServiceAccounts
                                            .filter((sa: any) => sa.environment === env && sa.status === "ACTIVE" && sa.hasOwnProperty('authenticationType'))
                                            .map((serviceAccount: any) => renderServiceAccount(serviceAccount, "active"))
                                    }
                                </Flex>
                            </div>
                            <div className="saList">
                                <Flex vertical gap="large">
                                    {
                                        filteredServiceAccounts
                                            .filter((sa: any) => sa.environment === env && sa.status === "INACTIVE" && sa.hasOwnProperty('authenticationType'))
                                            .map((serviceAccount: any) => renderServiceAccount(serviceAccount, "inactive"))
                                    }
                                </Flex>
                            </div>
                            <div className="saList">
                                <Flex vertical gap="large">
                                    {
                                        filteredServiceAccounts
                                            .filter((sa: any) => sa.environment === env && sa.status === "INACTIVE" && !sa.hasOwnProperty('authenticationType'))
                                            .map((serviceAccount: any) => renderServiceAccount(serviceAccount, "noAuth"))
                                    }
                                </Flex>
                            </div>
                            {(showArchived || search !== '') &&
                                <div className="saList">
                                    <Flex vertical gap="large">
                                        {
                                            filteredServiceAccounts
                                                .filter((sa: any) => sa.environment === env && sa.status === "ARCHIVED").map((serviceAccount: any) => renderServiceAccount(serviceAccount, 'archived'))
                                        }
                                    </Flex>
                                </div>
                            }
                        </Flex>
                        :
                        <p>No Service Account</p>
                    }
                </Card>

                {showActivateModal && editedServiceAccount !== null &&
                    <CustomModal secureString={editedServiceAccount.name} title="Activate Service Account" message={`Are you sure you want to activate the Service Account named "${editedServiceAccount.name}" ? Please type in its name "${editedServiceAccount.name}" to confirm.`} callback={handleActivateModalResponse} />
                }
                {showDeactivateModal && editedServiceAccount !== null &&
                    <CustomModal secureString={editedServiceAccount.name} title="Deactivate Service Account" message={`Are you sure you want to deactivate the Service Account named "${editedServiceAccount.name}" ? Please type in its name "${editedServiceAccount.name}" to confirm.`} callback={handleDeactivateModalResponse} />
                }
                {showArchiveModal && editedServiceAccount !== null &&
                    <CustomModal secureString={editedServiceAccount.name} title="Deactivate Service Account" message={`Are you sure you want to archive the Service Account named "${editedServiceAccount.name}" ? This operation can not be undone. Please type in its name "${editedServiceAccount.name}" to confirm.`} callback={handleArchiveModalResponse} />
                }
            </Content>
        </SecureWithLogin>
    )
}