import '../../assets/scss/ClusterStatus.scss';

import { Card, Col, List, Row, Table, Tooltip } from 'antd';

import CollectionStatus from './CollectionStatus';
import { Content } from 'antd/es/layout/layout';
import { EnvContext } from '../../contexts/EnvironmentContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HomeOutlined } from '@ant-design/icons';
import { InfoCircleOutlined } from '@ant-design/icons';
import Loading from "../Layout/Loading";
import Moment from 'react-moment';
import MotionBox from "../Layout/Animated/MotionBox";
import { PageHeader } from '../Layout/header/PageHeader';
import { SecureWithLogin } from "../../utils/secure";
import ShowError from '../Layout/ShowError';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import useClusterStatus from "../../hooks/useClusterStatus"
import { useContext } from 'react';

export default function ClusterStatus() {
    const env: string = useContext(EnvContext);

    const { clusterStatus, loading, error } = useClusterStatus(env);
    console.log("🚀 ~ ClusterStatus ~ error:", error, clusterStatus);

    const renderStatusIcon = (status: string): string => {
        if (status === 'GREEN' || status === 'UP') {
            return 'success';
        } else if (status === 'YELLOW') {
            return 'warning';
        } else if (status === 'RED' || status === 'OUT_OF_SERVICE' || status === 'DOWN') {
            return 'fail';
        }
        return ''
    }

    const statusDisplay: any[] = [
        { key: 'Discovery', status: clusterStatus?.services?.discovery?.status, version: clusterStatus?.services?.discovery?.version, lastUpdate: clusterStatus?.services?.discovery?.lastUpdate, description: "A view on the cluster status" },
        { key: 'Screening', status: clusterStatus?.services?.screening?.status, version: clusterStatus?.services?.screening?.version, lastUpdate: clusterStatus?.services?.screening?.lastUpdate, description: "The api that is being used for screening" },
        { key: 'Updater', status: clusterStatus?.services?.updater?.status, version: clusterStatus?.services?.updater?.version, lastUpdate: clusterStatus?.services?.updater?.lastUpdate, description: "The component which is responsible for loading lists from vendors and update Neterium databases" },
        { key: 'Utilities', status: clusterStatus?.services?.utilities?.status, version: clusterStatus?.services?.utilities?.version, lastUpdate: clusterStatus?.services?.utilities?.lastUpdate, description: "The component which is used to define exceptions, presets but also retrieve information about a matched profile" },
    ]
    const columns = [
        {
            title: 'Name',
            dataIndex: 'key',
            key: 'key',
            render: (key: string, record: any) => {
                return (
                    <Tooltip title={record.description}>
                        {key} <InfoCircleOutlined />
                    </Tooltip>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => <span className={`statusBubble ${renderStatusIcon(status)}`}></span>
        },
        {
            title: 'Version',
            dataIndex: 'version',
            key: 'version',
        },
        {
            title: 'Last Update',
            dataIndex: 'lastUpdate',
            key: 'lastUpdate',
            render: (lastUpdate: string) => <Moment format="YYYY/MM/DD h:mm:ss a">{lastUpdate}</Moment>
        },
    ];

    return (
        <SecureWithLogin callbackPath="/clusterStatus" role={['portal_cluster_status']}>
            <MotionBox>

                <Content className="clusterStatus sectionCard">
                    {/* <SectionBanner title="Cluster Status" type="clusterStatus" /> */}
                    <PageHeader
                        withEnvSelector
                        title='Service Status'
                        subtitle='Dashboard'
                        breadcrumbs={[
                            { title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', },
                            { title: <span>Cluster Status</span> }]}
                    />
                    {(!loading && error === null) ?
                        <Row gutter={[24, 24]}>
                            <Col xs={24}>
                                <Card><p className="clusterMessage"><span className={`statusBubble ${renderStatusIcon(clusterStatus?.status)}`}></span>{clusterStatus?.message}</p>
                                </Card>
                            </Col>
                            <Col xs={24}>
                                <Card>
                                    <Table
                                        columns={columns}
                                        dataSource={statusDisplay}
                                        pagination={false}
                                        rowKey="key"
                                        scroll={{ x: 'max-content' }}
                                    />
                                </Card>
                            </Col>
                            <Col xs={24}>
                                <Card title="Available Collections Status">
                                    <List
                                        size="small"
                                        dataSource={clusterStatus?.availableCollections}
                                        renderItem={(item: string) => (
                                            <li><CollectionStatus targetRealm={env} collection={item} /></li>
                                        )}
                                        rowKey={(item: string) => item}
                                    />
                                    {env === 'prod' &&
                                        <div className="actions">
                                            <a className="customSmallButton base" href="https://status.neterium.io/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faUpRightFromSquare}></FontAwesomeIcon>{' '}View on uptime.com</a>
                                        </div>
                                    }
                                </Card>
                            </Col>
                        </Row>
                        :
                        <>
                            {loading && <Loading />}
                            {error && <ShowError error={`Unable to get the status of the ${env.toLowerCase()} environment`} />}
                        </>
                    }
                </Content>
            </MotionBox>
        </SecureWithLogin >
    )
}
