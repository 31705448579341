import '../../assets/scss/ListStatus.scss';

import { Card, Col, DatePicker, Flex, Row, Space, Statistic, Tabs } from 'antd';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useContext, useState } from 'react';

import { Content } from 'antd/es/layout/layout';
import { EnvContext } from '../../contexts/EnvironmentContext';
import { HomeOutlined } from '@ant-design/icons';
import Loading from "../Layout/Loading";
import MotionBox from '../Layout/Animated/MotionBox';
import { PageHeader } from '../Layout/header/PageHeader';
// import SectionBanner from '../Layout/SectionBanner';
import { SecureWithLogin } from "../../utils/secure";
import ShowError from '../Layout/ShowError';
import { UpOutlined } from '@ant-design/icons';
//import { UnorderedListOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import useLists from "../../hooks/useLists";

export default function ListStatus() {
    //Converts date to input form date format
    const toDateInputValue = (date: Date) => {
        let local: Date = date;
        local.setMinutes(local.getMinutes() - local.getTimezoneOffset());
        return local.toJSON().slice(0, 10);
    }
    //start date for init
    let d: Date = new Date();
    d.setDate(d.getDate() - 10);
    const env: string = useContext(EnvContext);

    const [startDate, setStartDate] = useState(toDateInputValue(d));
    const [endDate, setEndDate] = useState(toDateInputValue(new Date()));
    const [activeTabKey, setActiveTabKey] = useState<string>('0');

    const onTabChange = (key: string) => {
        setActiveTabKey(key);
    };

    const { lists, loading, error } = useLists(env, dayjs(startDate).format('YYYYMMDD'), dayjs(endDate).format('YYYYMMDD'));

    const [interactiveLegends, setInteractiveLegends] = useState<any>([
        { name: "total", hidden: false },
        { name: "new", hidden: true },
        { name: "updated", hidden: true },
        { name: "deleted", hidden: true }
    ]);
    // const labels = [
    //     { key: "prosumer1", color: "#8884d8" },
    //     { key: "prosumer2", color: "#82ca9d" },
    //     { key: "prosumer3", color: "#81cc2d" }
    // ];
    // const [barProps, setBarProps] = useState(
    //     labels.reduce(
    //         (a:any, { key }) => {
    //             a[key] = false;
    //             return a;
    //         },
    //         { hover: null }
    //     )
    // );

    const selectLine = (e: any) => {
        const { dataKey } = e;
        const newInteractiveLegends = interactiveLegends.map((l: any) => {
            if (l.name === dataKey) {
                l.hidden = false;
            } else {
                l.hidden = true;
            }
            return l;
        });

        setInteractiveLegends(newInteractiveLegends);
    };

    const getChart = (l: ListStatus) => {
        if (l.history.length === 0) return <p>No Data available</p>;

        const chartTotal: any[] = [
            {
                name: "Total",
                xAxisKey: 'executionTime',
                xAxisLabel: 'Exec' + Math.random(),
                areaKey: [{
                    entry: 'total',
                    stroke: '#334256',
                    fill: '#38bdf8'
                },
                {
                    entry: 'new',
                    stroke: '#82ca9d',
                    fill: '#82ca9d'
                },
                {
                    entry: 'updated',
                    stroke: '#ffc658',
                    fill: '#ffc658'
                },
                {
                    entry: 'deleted',
                    stroke: '#ff0000',
                    fill: '#ff0000'
                }],
                data: l.chartData
            }
        ]


        return <div className="graphs" key={l.key}>
            {
                chartTotal.map((c: any) => {
                    return (
                        <div className="graph" key={c.name}>
                            {/* <ResponsiveContainer max-width="100%" height={450}>

                                <BarChart

                                    width={500}
                                    height={300}
                                    data={c.data}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 10,
                                        bottom: 10
                                    }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey={c.xAxisKey} />
                                    <YAxis domain={[0, (dataMax: any) => (Math.round(dataMax * 1.2))]} allowDecimals={false} />

                                    <Tooltip />
                                    <Legend onClick={selectBar} />
                                    {c.areaKey.map((k: { entry: string, stroke: string, fill: string }) => {
                                        return <Bar
                                            key={k.entry}
                                            type="monotone"
                                            dataKey={k.entry}
                                            stroke={k.stroke}
                                            fill={k.fill}
                                            hide={interactiveLegends.find((l: any) => l.name === k.entry)?.hidden}
                                            strokeWidth={0}
                                        />

                                    })}
                                </BarChart>
                            </ResponsiveContainer> */}
                            <ResponsiveContainer max-width="100%" height={450}>
                            <LineChart
                                width={500}
                                height={300}
                                data={c.data}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 10
                                }}
                            >
                                <CartesianGrid horizontal strokeDasharray="3" vertical={false} />
                                <XAxis dataKey={c.xAxisKey} />
                                    <YAxis domain={[0, (dataMax: any) => (Math.round(dataMax * 1.2))]} allowDecimals={false} />

                                    <Tooltip />
                                    <Legend onClick={selectLine} />
                                    {c.areaKey.map((k: { entry: string, stroke: string, fill: string }) => {
                                        return <Line
                                            key={k.entry}
                                            type="monotone"
                                            dataKey={k.entry}
                                            hide={interactiveLegends.find((l: any) => l.name === k.entry)?.hidden}
                                            stroke={k.stroke}
                                            dot={false}
                                        />

                                    })}
                            </LineChart>
                            </ResponsiveContainer>
                            <Flex justify='center'>
                                <p className="notice">Toggle the display by clicking on the legend</p>
                            </Flex>
                        </div>
                    )
                })
            }
        </div>
    }

    const getStatIcon = (value: number) => {
        if (value === undefined) return (null);
        return value > 0 ? <UpOutlined /> : null;
    }
    const getStatColor = (value: number) => {
        return value > 0 ? { color: '#3f8600' } : { color: 'rgba(0, 0, 0, 0.88)' }
    }

    return (
        <SecureWithLogin callbackPath="/listStatus" role={['portal_list_status']}>


            <Content className='list-status'>
                {/* <SectionBanner title="List Status" type="listStatus" /> */}
                <MotionBox>
                    <PageHeader
                        withEnvSelector
                        title='List Status'
                        subtitle='Dashboard'
                        breadcrumbs={[
                            { title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', },
                            { title: <span>List Status</span> }]}
                    />
                    <Row gutter={[24,24]}>
                    {(!loading && error === null && lists.length) ?
                    <>
                        <Col xs={24}>
                            <Card
                            className='list-status-card-nav'
                            extra={
                                <Space size={"small"}>
                                    <label htmlFor="listStatusDateFilter" >Filter by Date Range</label>
                                    <DatePicker.RangePicker
                                        id="listStatusDateFilter"
                                        name="listStatusDateFilter"
                                        allowClear={false}
                                        defaultValue={[dayjs(startDate), dayjs(endDate)]}
                                        onChange={(date: any, dateString: string[]) => {
                                            setStartDate(dateString[0])
                                            setEndDate(dateString[1])
                                        }} />
                                </Space>
                            }
                            >
                                <Tabs
                                    items={lists.map((l: ListStatus, index: number) => {
                                        return {
                                            key: index.toString(),
                                            label: l.name + " (" + l.size + " Records )"
                                        }
                                    })}
                                    onChange={onTabChange}
                                    style={{ textTransform: 'capitalize' }}
                                />
                            </Card>
                        </Col>
                        <Col xs={24} xxl={6}>
                        {lists[Number(activeTabKey)].history.length > 0 &&
                                    <Card>
                                        <Row gutter={[12,12]}>
                                            <Col xs={24} lg={8} xxl={24}>
                                                <Statistic style={{ textAlign: 'center' }}
                                                    title="New records"
                                                    value={lists[Number(activeTabKey)].history[lists[Number(activeTabKey)].history.length - 1]?.database.newRecords}
                                                    prefix={getStatIcon(lists[Number(activeTabKey)].history[lists[Number(activeTabKey)].history.length - 1]?.database.newRecords)}
                                                    valueStyle={getStatColor(lists[Number(activeTabKey)].history[lists[Number(activeTabKey)].history.length - 1]?.database.newRecords)}
                                                />
                                            </Col>
                                            <Col xs={24} lg={8} xxl={24}>
                                                <Statistic style={{ textAlign: 'center' }}
                                                    title="Deleted records"
                                                    value={lists[Number(activeTabKey)].history[lists[Number(activeTabKey)].history.length - 1]?.database.deletedRecords + lists[Number(activeTabKey)].history[lists[Number(activeTabKey)].history.length - 1].database.purgedRecords}
                                                    prefix={getStatIcon(lists[Number(activeTabKey)].history[lists[Number(activeTabKey)].history.length - 1]?.database.deletedRecords + lists[Number(activeTabKey)].history[lists[Number(activeTabKey)].history.length - 1].database.purgedRecords)}
                                                    valueStyle={getStatColor(lists[Number(activeTabKey)].history[lists[Number(activeTabKey)].history.length - 1]?.database.deletedRecords + lists[Number(activeTabKey)].history[lists[Number(activeTabKey)].history.length - 1].database.purgedRecords)}
                                                />
                                            </Col>
                                            <Col xs={24} lg={8} xxl={24}>
                                                <Statistic style={{ textAlign: 'center' }}
                                                    title="Updated records"
                                                    value={lists[Number(activeTabKey)].history[lists[Number(activeTabKey)].history.length - 1]?.database.updatedRecords}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                }
                        </Col>
                        <Col xs={24} xxl={18}>
                        <Card>
                            <Flex gap={"large"} vertical>
                                <Flex justify='center' gap={"large"} wrap={"wrap"} >
                                    <p>
                                        Publication date: {lists[Number(activeTabKey)].lastPublished ? dayjs(lists[Number(activeTabKey)].lastPublished).format('YYYY-MM-DD HH:mm:ss') + ' GMT' : "N/A"}
                                    </p>
                                    <p>
                                        Last Updated: {lists[Number(activeTabKey)].lastUpdated ? dayjs(lists[Number(activeTabKey)].lastUpdated).format('YYYY-MM-DD HH:mm:ss') + ' GMT' : "N/A"}
                                    </p>
                                    {/* <Statistic
                                    title="Publication Date"
                                    value={lists[Number(activeTabKey)].lastPublished ? dayjs(lists[Number(activeTabKey)].lastPublished).format('YYYY-MM-DD HH:mm:ss') + ' GMT' : "N/A"}
                                />
                                <Statistic
                                    title="Last Updated"
                                    value={lists[Number(activeTabKey)].lastUpdated ? dayjs(lists[Number(activeTabKey)].lastUpdated).format('YYYY-MM-DD HH:mm:ss') + ' GMT' : "N/A"}
                                /> */}
                                </Flex>
                            </Flex>
                            {getChart(lists[Number(activeTabKey)])}
                        </Card>
                        </Col>
                        </>
                        :
                        <Col xs={24}>
                            {loading && <Loading />}
                            {error && <ShowError error={error} />}
                        </Col>
                    }
                    </Row>
                </MotionBox>
            </Content>
        </SecureWithLogin >
    )
}
